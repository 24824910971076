<template>
	<div class="main">
		<el-card class="card_box">
			<div
				slot="header"
				class="card_title"
				@click="$router.push({ path: '/user/adduser' })"
			>
				<i class="el-icon-arrow-left"></i>
				<span>用户管理/用户列表/详情</span>
			</div>
			<div class="add_main">
				<span class="font_weight_bold">账号信息</span>
				<div class="margin_top_20">
					<el-form
						label-position="right"
						label-width="120px"
						:model="formData"
						:rules="rule"
						ref="formData"
					>
						<!-- <el-form-item label="头像">
                            <el-logo
                                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-logo>
                        </el-form-item> -->
						<el-form-item label="账号名称" prop="nickname">
							<el-input
								v-model="formData.nickname"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>

						<el-form-item label="用户密码" prop="password">
							<el-input v-model="formData.passwordNew"> </el-input>
						</el-form-item>
						<!-- <el-form-item label="性别">
                            <el-select v-model="formData.type" placeholder="请选择性别">
                                <el-option label="男" value="男">
                                </el-option>
                                <el-option label="女" value="女">
                                </el-option>
                            </el-select>
                        </el-form-item> -->

						<!-- <el-form-item label="职位">
                            <el-input v-model="formData.type"></el-input>
                        </el-form-item>
                        <el-form-item label="介绍">
                            <el-input v-model="formData.type"></el-input>
                        </el-form-item>
                        <el-form-item label="微信">
                            <el-input v-model="formData.type"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱">
                            <el-input v-model="formData.type"></el-input>
                        </el-form-item>

                        <el-form-item label="twitter">
                            <el-input v-model="formData.type"></el-input>
                        </el-form-item> -->

						<!-- <el-form-item label="账号权限">
                            <el-radio v-model="radio" label="1">评论</el-radio>
                            <el-radio v-model="radio" label="2">发表</el-radio>
                        </el-form-item> -->
						<el-form-item label="账号状态" prop="state">
							<el-select
								v-model="formData.state"
								:disabled="$route.query.type == 'detail'"
								placeholder="请选择账号状态"
							>
								<el-option label="正常" value="1"> </el-option>
								<el-option label="禁用" value="2"> </el-option>
							</el-select>
						</el-form-item>
						<div class="margin_top_40 font_weight_bold">企业信息</div>
						<el-form-item
							label="企业名称"
							class="margin_top_20"
							prop="userinfo.company_name"
						>
							<el-input
								v-model="formData.userinfo.company_name"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="企业简介" prop="userinfo.company_desc">
							<el-input
								v-model="formData.userinfo.company_desc"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<!-- <el-form-item label="营业执照注册号" prop="userinfo.company_no">
                            <el-input v-model="formData.userinfo.company_no" :disabled="$route.query.type=='detail'"></el-input>
                        </el-form-item> -->
						<el-form-item label="营业执照注册号">
							<el-input
								v-model="formData.userinfo.company_no"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<div class="margin_top_40 font_weight_bold">营业执照</div>
						<el-form-item class="margin_top_20">
							<div class="flex">
								<el-upload
									class="upload-demo"
									:disabled="$route.query.type == 'detail'"
									action=""
									:show-file-list="false"
									:http-request="uploadImagecompany1"
									:drag="true"
								>
									<img
										v-if="formData.userinfo.company_img1"
										:src="formData.userinfo.company_img1"
										class="logo"
									/>
									<div v-else>
										<i class="el-icon-upload"></i>
										<div class="el-upload__text"
											>将营业执照正面拖到此处，或<em>点击上传</em></div
										>
									</div>
								</el-upload>
								<el-upload
									class="upload-demo"
									:disabled="$route.query.type == 'detail'"
									action=""
									:show-file-list="false"
									:http-request="uploadImagecompany2"
									:drag="true"
								>
									<img
										v-if="formData.userinfo.company_img2"
										:src="formData.userinfo.company_img2"
										class="logo"
									/>
									<div v-else>
										<i class="el-icon-upload"></i>
										<div class="el-upload__text"
											>将营业执照反面拖到此处，或<em>点击上传</em></div
										>
									</div>
								</el-upload>
							</div>
						</el-form-item>
						<el-form-item label="企业LOGO" prop="avatar_img">
							<img
								style="width: 30%"
								v-if="formData.userinfo.project_logo"
								:src="formData.userinfo.project_logo"
								:disabled="$route.query.type == 'detail'"
								@click="uploadPicture('0')"
							/>
							<div v-else>
								<div class="el-upload__text" @click="uploadPicture(0)">
									<img
										style="width: 100px"
										src="../../../assets/images/uploadImg.png"
										alt=""
									/>
									<br /><em>点击加号上传</em></div
								>
							</div>
							<!-- 剪裁组件弹窗 -->
							<el-dialog
								title="裁切图片"
								:visible.sync="cropperModel"
								width="1300px"
								center
								:append-to-body="true"
							>
								<cropper-image
									:Name="cropperName"
									@uploadImgSuccess="handleUploadSuccess"
									ref="child"
								>
								</cropper-image>
							</el-dialog>
						</el-form-item>

						<div class="margin_top_40 font_weight_bold">运营者信息</div>
						<el-form-item
							label="姓名"
							class="margin_top_20"
							prop="userinfo.truename"
						>
							<el-input
								:disabled="$route.query.type == 'detail'"
								v-model="formData.userinfo.truename"
							></el-input>
						</el-form-item>
						<!-- <el-form-item label="身份证号" prop="userinfo.id_card">
                            <el-input :disabled="$route.query.type=='detail'" v-model="formData.userinfo.id_card"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号码" prop="mobile">
                            <el-input :disabled="$route.query.type=='detail'" v-model="formData.mobile"></el-input>
                        </el-form-item> -->
						<el-form-item label="身份证号">
							<el-input
								:disabled="$route.query.type == 'detail'"
								v-model="formData.userinfo.id_card"
							></el-input>
						</el-form-item>
						<el-form-item label="手机号码" prop="mobile">
							<el-input
								:disabled="$route.query.type == 'detail'"
								v-model="formData.mobile"
							></el-input>
						</el-form-item>
						<div class="margin_top_40 font_weight_bold">请上传身份证正反面</div>
						<el-form-item class="margin_top_20">
							<div>
								<el-upload
									class="upload-demo"
									:disabled="$route.query.type == 'detail'"
									action=""
									:show-file-list="false"
									:http-request="uploadImagecard1"
									:drag="true"
								>
									<img
										v-if="formData.userinfo.id_card_img1"
										:src="formData.userinfo.id_card_img1"
										class="logo"
									/>
									<div v-else>
										<i class="el-icon-upload"></i>
										<div class="el-upload__text"
											>将身份证正面拖到此处，或<em>点击上传</em></div
										>
									</div>
								</el-upload>
								<el-upload
									class="upload-demo"
									action=""
									:show-file-list="false"
									:disabled="$route.query.type == 'detail'"
									:http-request="uploadImagecard2"
									:drag="true"
								>
									<img
										v-if="formData.userinfo.id_card_img2"
										:src="formData.userinfo.id_card_img2"
										class="logo"
									/>
									<div v-else>
										<i class="el-icon-upload"></i>
										<div class="el-upload__text"
											>将身份证反面拖到此处，或<em>点击上传</em></div
										>
									</div>
								</el-upload>
							</div>
						</el-form-item>
						<el-form-item>
							<div class="flex justify_content_c align_items_c">
								<el-button
									type="primary"
									v-if="$route.query.type != 'detail'"
									class="custom_btn_width_100"
									@click="submit('formData')"
									>保存</el-button
								>
								<el-button class="custom_btn_width_100" @click="$router.back()"
									>返回</el-button
								>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import { validateNull, identityCard, validatePhone } from "@/utils/validate";

import {
	uploadImage,
	getUsersdetails,
	createusers,
	updateusers,
} from "@/api/user";
export default {
	name: "",
	data() {
		return {
			rule: {
				nickname: [
					{ validator: validateNull, trigger: "blur", required: true },
				],
				state: [{ validator: validateNull, trigger: "change", required: true }],
				"userinfo.company_name": [
					{ validator: validateNull, trigger: "blur", required: true },
				],
				"userinfo.company_desc": [
					{ validator: validateNull, trigger: "blur", required: true },
				],

				// "userinfo.company_img1": [{ validator: validateNull, trigger: "change", required: true }],
				// "userinfo.project_logo": [{ validator: validateNull, trigger: "change", required: true }],

				// "userinfo.truename": [
				// 	{ validator: validateNull, trigger: "blur", required: true },
				// ],

				// "userinfo.id_card": [
				// 	{ validator: validateNull, trigger: "blur", required: true },
				// 	{ validator: identityCard, trigger: "blur" },
				// ],

				// mobile: [
				// 	{ validator: validateNull, trigger: "blur", required: true },
				// 	{ validator: validatePhone, trigger: "blur" },
				// ],
				// "userinfo.id_card_img1": [{ validator: validateNull, trigger: "change", required: true }],
			},
			value: "",
			formData: {
				state: "",
				nickname: "",
				mobile: "",
				avatar_img: "",
				sex: "",
				desc: "",
				position: "",
				email: "",
				wechat: "",
				twitter: "",
				document_type: "",

				project_type: "",
				userinfo: {
					auth_type: "2",
					company_name: "",
					company_desc: "",
					company_no: "",
					company_img1: "",
					company_img2: "",
					project_logo: "",
					truename: "",
					id_card: "",
					id_card_img1: "",
					id_card_img2: "",
					project_name: "",
					project_contacts: "",
					project_phone: "",
					project_url: "",
					social_media: "",
					audit_report: "",
					github_url: "",
				},
			},
			//裁切图片参数
			cropperModel: false,
			cropperName: "",
		};
	},
	created() {
		if (!this.$route.query.id) {
			this.clearFormAdd();
		} else {
			this.getUsersdetails();
		}
	},
	methods: {
		//封面设置
		uploadPicture(name) {
			this.cropperName = name;
			this.cropperModel = true;
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			console.log(data.url);
			this.cropperModel = false;
			if (this.cropperName == "0") {
				this.formData.userinfo.project_logo = data.url;
			} else if (this.cropperName == "1") {
			} else if (this.cropperName == "2") {
			} else {
			}
		},
		// 获取用户详情
		getUsersdetails() {
			const that = this;
			getUsersdetails({ d: that.$route.query.id })
				.then((res) => {
					if (res.code == 200) {
						that.formData = res.data;
						that.formData.state = that.formData.state.toString();
					} else {
						that.$message.error(res.message);
					}
				})
				.catch((error) => {
					that.$message.error(error.message);
				});
		},
		// 新增
		submit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					const that = this;

					let form = {};
					if (
						this.formData.passwordNew != null &&
						this.formData.passwordNew.length > 0
					) {
						this.formData.password = this.formData.passwordNew;
					} else {
						delete this.formData.password;
					}

					this.formData.userinfo.auth_type = "2";
					if (
						this.formData.userinfo.project_logo != "" &&
						this.formData.userinfo.project_logo != null &&
						this.formData.userinfo.project_logo.length > 10
					) {
						this.formData.avatar_img = this.formData.userinfo.project_logo;
					}
					if (
						this.formData.userinfo.company_desc != "" &&
						this.formData.userinfo.company_desc != null &&
						this.formData.userinfo.company_desc.length > 0
					) {
						this.formData.desc = this.formData.userinfo.company_desc;
					}
					for (let key in this.formData) {
						if (key === "userinfo") {
							for (let key2 in this.formData.userinfo) {
								form[key2] = this.formData.userinfo[key2];
							}
						} else {
							form[key] = this.formData[key];
						}
					}
					if (this.$route.query.type == "edit") {
						updateusers(form)
							.then((res) => {
								if (res.code == 200) {
									that.$router.push({ path: "/user/userlist" });
								} else {
								}
							})
							.catch((error) => {});
					} else {
						createusers(form)
							.then((res) => {
								if (res.code == 200) {
									that.$router.push({ path: "/user/userlist" });
								} else {
								}
							})
							.catch((error) => {});
					}
					// }
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		// 上传图片
		uploadImagecompany1(obj) {
			const file = obj.file;
			let formdata = new FormData();
			formdata.append("file", file);
			const that = this;
			uploadImage(formdata)
				.then(function (res) {
					if (res.code == 200) {
						console.log(res.data.url);
						that.formData.userinfo.company_img1 = res.data.url;
					} else {
						that.$message.error(res.message);
					}
				})
				.catch(function (error) {
					this.$message.error(res.message);
				});
		},
		uploadImagecompany2(obj) {
			const file = obj.file;
			let formdata = new FormData();
			formdata.append("file", file);
			const that = this;
			uploadImage(formdata)
				.then(function (res) {
					if (res.code == 200) {
						console.log(res.data.url);
						that.formData.userinfo.company_img2 = res.data.url;
					} else {
						that.$message.error(res.message);
					}
				})
				.catch(function (error) {
					this.$message.error(res.message);
				});
		},

		uploadImagecard1(obj) {
			const file = obj.file;
			let formdata = new FormData();
			formdata.append("file", file);
			const that = this;
			uploadImage(formdata)
				.then(function (res) {
					if (res.code == 200) {
						console.log(res.data.url);
						that.formData.userinfo.id_card_img1 = res.data.url;
					} else {
						that.$message.error(res.message);
					}
				})
				.catch(function (error) {
					this.$message.error(res.message);
				});
		},
		uploadImagecard2(obj) {
			const file = obj.file;
			let formdata = new FormData();
			formdata.append("file", file);
			const that = this;
			uploadImage(formdata)
				.then(function (res) {
					if (res.code == 200) {
						console.log(res.data.url);
						that.formData.userinfo.id_card_img2 = res.data.url;
					} else {
						that.$message.error(res.message);
					}
				})
				.catch(function (error) {
					this.$message.error(res.message);
				});
		},
		clearFormAdd() {
			for (let key in this.formData) {
				if (key === "userinfo") {
					for (let key2 in this.formData.userinfo) {
						this.formData.userinfo[key2] = "";
					}
				} else {
					this.formData[key] = "";
				}
			}
			console.log(this.formData);
		},
	},
};
</script>

<style scoped>
.main {
	width: calc(100% - 40px);
}

.add_main {
	width: 70%;
	margin: 0 auto;
}
</style>
